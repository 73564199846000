import React, { useEffect, useState } from 'react'
import { Link, Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import moment from 'moment'

// import Footer from '../../components/ui/Footer'
// import Intercom from 'react-intercom'
import PropTypes from 'prop-types'
import Routes from '../../routes'
import apollo from '../../api/client'
import { useDispatch, useSelector } from 'react-redux'
import firebase from 'firebase/app'
import { gql } from 'apollo-boost-upload'
import NewFooter from '../../components/newFooter/NewFooter'
import { IS_PHONE_VALID_AND_VERIFIED, SET_PHONE } from '../../store/actions'
import { persistor } from '../../store/index'
import './styles.less'
// import HelpCrunch from '../../components/helpcrunch'
import { CustomerlyProvider } from 'react-live-chat-customerly'
import CustomerlyChat from '../../components/customerly'
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

firebase.initializeApp(config)

// const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID

const App = () => {
  const customerlyId = process.env.REACT_APP_CUSTOMERLY_ID;
  const dispatch = useDispatch()
  const history = useHistory()
  const [mobileMenu, setMobileMenu] = useState(false)
  const [user, setUser] = useState(null)
  const [authState, setAuthState] = useState({ status: 'loading' })
  const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
  const [requestedPath, setRequestedPath] = useState(useLocation().pathname)
  const isPhoneValidAndVerified = useSelector(state => state.isPhoneValidAndVerified)

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (user: Object) => {
      try {
        if (user) {
          let agreeMarkting = null
          let agreeMarktingCookie = localStorage.getItem('AGREE_MRKETING')
          agreeMarktingCookie =
            agreeMarktingCookie !== undefined ? JSON.parse(agreeMarktingCookie) : null
          if (
            agreeMarktingCookie !== undefined &&
            agreeMarktingCookie !== null &&
            user.email === agreeMarktingCookie.email
          ) {
            agreeMarkting = agreeMarktingCookie.agreeMarkting
            localStorage.removeItem('AGREE_MRKETING')
          }

          const token = await user.getIdToken()

          Cookies.set('MUDEY_wallet_token', token)

          const companyId = Cookies.get('MUDEY_company', { domain: domain }) || 'Mudey'
          const managerId = Cookies.get('MUDEY_manager', { domain: domain }) || ''
          const consents = {
            privacyConsent: true,
            insuranceConsent: true,
            marketingConsent: agreeMarkting,
            createdAt: moment().format(),
            updatedAt: ''
          }

          const TIMEOUT = user.displayName ? 0 : 1000
          setTimeout(async () => {
            // Authenticate with Mudey API
            const { data } = await apollo.query({
              variables: { companyId, managerId, consents },
              query: gql`
                query userLogin(
                  $companyId: String
                  $managerId: String
                  $consents: UserConsentsInput
                ) {
                  userLogin(
                    input: { companyId: $companyId, managerId: $managerId, consents: $consents }
                  ) {
                    uuid
                    firstName
                    lastName
                    email
                    companyId
                    managerId
                    phone
                    isPhoneValid
                  }
                }
              `,
              fetchPolicy: 'no-cache'
            })
            /* const { data } = await apollo.query({
              query: gql`
                {
                  userLogin {
                    uuid
                    firstName
                    lastName
                    email
                  }
                }
              `,
              fetchPolicy: 'no-cache'
            }) */
            // this is being done in backend need to see whats needed to remove this set
            setUser({
              user_id: user.uid,
              email: user.email,
              name: data.userLogin.firstName,
              phone: data.userLogin.phone
            })

            // Set cookies with firebase user response
            Cookies.set('MUDEY_wallet_uuid', user.uid, { domain: domain })
            Cookies.set('MUDEY_wallet_email', user.email, { domain: domain })
            Cookies.set('MUDEY_wallet_name', data.userLogin.firstName, { domain: domain })
            Cookies.set('MUDEY_company_handler', data.userLogin.companyId, { domain: domain })
            Cookies.set('MUDEY_manager_handler', data.userLogin.managerId, { domain: domain })

            setAuthState({ status: 'in' })
            const isPhoneValid = data.userLogin.isPhoneValid
            const phone = data.userLogin.phone
            dispatch({
              type: SET_PHONE,
              payload: phone
            })

            if (isPhoneValid) {
              dispatch({
                type: IS_PHONE_VALID_AND_VERIFIED,
                payload: true
              })
              // return history.push('/seguros')
            } else if (!isPhoneValid && location.pathname != '/verify-otp') {
              dispatch({
                type: IS_PHONE_VALID_AND_VERIFIED,
                payload: false
              })
              return history.push('/verify-number')
            }
          }, TIMEOUT)
        } else {
          signOut()
        }
      } catch (e) {
        console.log('Error', e)
        signOut()
      }
    })
  }, [])

  // useEffect(() => {
  //   console.log(isPhoneValidAndVerified, 'isPhoneValidAndVerified')
  //   if (isPhoneValidAndVerified) {
  //     return history.push('/seguros')
  //   } else {
  //     return history.push('/verify-number')
  //   }
  // }, [isPhoneValidAndVerified])

  // useEffect(() => {
  //   if (isPhoneValidAndVerified) {
  //     history.push('/seguros')
  //   } else {
  //     history.push('/verify-number')
  //   }
  // }, [location.pathname])

  const signOut = async () => {
    setAuthState({ status: 'out' })
    setUser(null)
    firebase.auth().signOut()
    Cookies.remove('MUDEY_wallet_uuid', { domain: domain })
    Cookies.remove('MUDEY_wallet_token')
    Cookies.remove('MUDEY_wallet_email', { domain: domain })
    Cookies.remove('MUDEY_wallet_name', { domain: domain })
    Cookies.remove('MUDEY_company_handler', { domain: domain })
    Cookies.remove('MUDEY_manager_handler', { domain: domain })
    persistor.purge()
  }

  const hideMenu = path => {
    setMobileMenu(false)
    setRequestedPath(path)
  }

  if (authState.status === 'loading') return ''
  const disabledStyle = {
    pointerEvents: 'none',
    color: '#ccc' // Some greyed out color or any style you'd like
  }
  const content =
    authState.status === 'in' ? (
      <div className="main-container">
        <div className={'side-menu ' + (mobileMenu ? '' : 'hidden-list')}>
          <img
            alt=""
            className="menu-trigger"
            onClick={() => {
              setMobileMenu(!mobileMenu)
            }}
            src={require('../../images/icons/bars.svg')}
          />
          <a href={process.env.REACT_APP_HP_URL}>
            <div className="logo_micro" />
          </a>
          <div className="logo" />
          <div className={'navigation ' + (mobileMenu ? '' : 'hidden-sm')}>
            <ul>
              {/* <li className={requestedPath == Routes.dashboard.path ? 'active' : ''}>
                  <Link
                    onClick={() => {
                      hideMenu(Routes.dashboard.path)
                      window.scrollTo(0, 0)
                    }}
                    to={Routes.dashboard.path}>
                    <img alt="" src={require('../../images/icons/icon5.svg')} />
                    <img alt="" src={require('../../images/icons/icon5u.svg')} />
                    <span>O Meu Dashboard</span>
                  </Link>
                </li> */}
              <li className={requestedPath == Routes.settings.userview.path ? 'active' : ''}>
                <Link
                  onClick={() => {
                    hideMenu(Routes.settings.userview.path)
                    window.scrollTo(0, 0)
                  }}
                  style={isPhoneValidAndVerified ? {} : disabledStyle}
                  to={Routes.settings.userview.path}>
                  <img alt="" src={require('../../images/icons/icon2.svg')} />
                  <img alt="" src={require('../../images/icons/icon2u.svg')} />
                  <span>Os Meus Dados</span>
                </Link>
              </li>
              <li className={requestedPath == Routes.policy.list.path ? 'active' : ''}>
                <Link
                  onClick={() => {
                    hideMenu(Routes.policy.list.path)
                    window.scrollTo(0, 0)
                  }}
                  style={isPhoneValidAndVerified ? {} : disabledStyle}
                  to={{ pathname: Routes.policy.list.path, state: 'flush' }}>
                  <img alt="" src={require('../../images/icons/icon1.svg')} />
                  <img alt="" src={require('../../images/icons/icon1u.svg')} />
                  <span>Os Meus Seguros</span>
                </Link>
              </li>
              <li className={requestedPath == Routes.advisor.path ? 'active' : ''}>
                <Link
                  onClick={() => {
                    hideMenu(Routes.advisor.path)
                    window.scrollTo(0, 0)
                  }}
                  style={isPhoneValidAndVerified ? {} : disabledStyle}
                  to={Routes.advisor.path}>
                  <img alt="" src={require('../../images/icons/icon8.svg')} />
                  <img alt="" src={require('../../images/icons/icon8u.svg')} />
                  <span>O Meu Consultor</span>
                </Link>
              </li>
              <li className={requestedPath == Routes.policy.applications.path ? 'active' : ''}>
                <Link
                  onClick={() => {
                    hideMenu(Routes.policy.applications.path)
                    window.scrollTo(0, 0)
                  }}
                  style={isPhoneValidAndVerified ? {} : disabledStyle}
                  to={Routes.policy.applications.path}>
                  <img alt="" src={require('../../images/icons/icon6.svg')} />
                  <img alt="" src={require('../../images/icons/icon6u.svg')} />
                  <span>As Minhas Simulações</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => {
                    hideMenu()
                  }}
                  to={Routes.helpcenter.list.path}>
                  <img alt="" src={require('../../images/icons/icon4.svg')} />
                  <img alt="" src={require('../../images/icons/icon4u.svg')} />
                  <span>Curiosidades</span>
                </Link>
              </li> */}
              {/* <li className={requestedPath == Routes.referrals.path ? 'active' : ''}>
                  <Link
                    onClick={() => {
                      hideMenu(Routes.referrals.path)
                      window.scrollTo(0, 0)
                    }}
                    to={Routes.referrals.path}>
                    <img alt="" src={require('../../images/icons/icon7.svg')} />
                    <img alt="" src={require('../../images/icons/icon7u.svg')} />
                    <span>Os Meus Convites</span>
                  </Link>
                </li> */}
              <li className={requestedPath == Routes.settings.notifications.path ? 'active' : ''}>
                <Link
                  onClick={() => {
                    hideMenu(Routes.settings.notifications.path)
                    window.scrollTo(0, 0)
                  }}
                  style={isPhoneValidAndVerified ? {} : disabledStyle}
                  to={Routes.settings.notifications.path}>
                  <img alt="" src={require('../../images/icons/icon3.svg')} />
                  <img alt="" src={require('../../images/icons/icon3u.svg')} />
                  <span>Definições</span>
                </Link>
              </li>

              <Link
                onClick={() => {
                  // window.location = '/comprar'
                  hideMenu(Routes.selectProduct.path)
                  window.scrollTo(0, 0)
                }}
                to={Routes.selectProduct.path}
                style={{ textDecoration: 'none', color: 'white' }}>
                <li className="buy-policy">
                  <img alt="" src={require('../../images/icons/buy.svg')} />{' '}
                  <span>Simular Seguro</span>
                </li>
              </Link>
            </ul>
          </div>
          <div className={'logout ' + (mobileMenu ? '' : 'hidden-sm')} onClick={signOut}>
            <img alt="" src={require('../../images/icons/logout.svg')} /> <span>Sair</span>
          </div>
        </div>
        <div className="content-block">
          <Switch>
            <Route component={Routes.validate.component} exact path={Routes.validate.path} />
            <Route component={Routes.verify.component} exact path={Routes.verify.path} />
            <Route component={Routes.dashboard.component} exact path={Routes.dashboard.path} />
            <Route component={Routes.home.component} exact path={Routes.home.path} />
            <Route
              component={Routes.policy.addExisting.component}
              exact
              path={Routes.policy.addExisting.path}
            />
            <Route component={Routes.advisor.component} exact path={Routes.advisor.path} />
            <Route
              component={Routes.advisorForms.component}
              exact
              path={Routes.advisorForms.path}
            />
            <Route
              component={Routes.advisorSuggestion.component}
              exact
              path={Routes.advisorSuggestion.path}
            />
            <Route
              component={Routes.selectProduct.component}
              exact
              path={Routes.selectProduct.path}
            />
            {/* <Route
                component={Routes.advisor.questionnaire.component}
                exact
                path={Routes.advisor.questionnaire.path}
              /> */}
            <Route
              component={Routes.policy.email.component}
              exact
              path={Routes.policy.email.path}
            />
            <Route component={Routes.policy.list.component} exact path={Routes.policy.list.path} />
            <Route
              component={Routes.policy.applications.component}
              exact
              path={Routes.policy.applications.path}
            />
            <Route
              component={Routes.policy.details.component}
              exact
              path={Routes.policy.details.path}
            />
            <Route
              component={Routes.helpcenter.list.component}
              exact
              path={Routes.helpcenter.list.path}
            />
            <Route
              component={Routes.settings.user.component}
              exact
              path={Routes.settings.user.path}
            />
            <Route
              component={Routes.settings.userview.component}
              exact
              path={Routes.settings.userview.path}
            />
            <Route
              component={Routes.settings.notifications.component}
              exact
              path={Routes.settings.notifications.path}
            />
            <Route
              component={Routes.helpcenter.details.component}
              exact
              path={Routes.helpcenter.details.path}
            />
            <Route component={Routes.referrals.component} exact path={Routes.referrals.path} />
            <Route path="*">
              <Redirect to="/seguros" />
            </Route>
          </Switch>
          {/* <Footer /> */}
        </div>
        <NewFooter />
      </div>
    ) : (
      <>
        <div className="auth-page">
          <div className="auth-logo">
            <a href={process.env.REACT_APP_HP_URL}>
              <img alt="logo" src={require('../../images/logo.svg')} />
            </a>
          </div>
          <div className="auth-container">
            <Switch>
              <Route component={Routes.auth.component} exact path={Routes.auth.path} />
              <Route component={Routes.restore.component} exact path={Routes.restore.path} />
              <Route component={Routes.register.component} exact path={Routes.register.path} />
              <Route path="*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </div>
        </div>
      </>
    )

  return (
    <>
      {content}
      {/* <Intercom appID={intercomAppId} {...user} /> */}
      {/* <HelpCrunch userData={user} /> */}
      <CustomerlyProvider appId={customerlyId}>
        <CustomerlyChat userData={user} />
      </CustomerlyProvider>
    </>
  )
}

App.propTypes = {
  history: PropTypes.any.isRequired
}

export default App
