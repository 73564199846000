import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

// **************** COMPONENTS ****************
// import PartnersHeader from '../../../../utils/partners-header'
import FirstSection from './components/FirstSection'
import SecondSection from './components/SecondSection'
import ThirdSection from './components/ThirdSection'
import FourthSection from './components/FourthSection'
import FifthSection from './components/FifthSection'
import SixthSection from './components/SixthSection'
import SeventhSection from './components/SeventhSection'
import FirstSectionHeading from './components/FirstSection/HeaderPanel/firstSectionHeading'
import SecondSectionHeading from './components/SecondSection/HeaderPanel/secondSectionHeading'
import ThirdSectionHeading from './components/ThirdSection/HeaderPanel/thirdSectionHeading'
import FourthSectionHeading from './components/FourthSection/HeaderPanel/fourthSectionHeading'
import FifthSectionHeading from './components/FifthSection/HeaderPanel/fifthSectionHeading'
import SixthSectionHeading from './components/SixthSection/HeaderPanel/sixthSectionHeading'
import SeventhSectionHeading from './components/SeventhSection/HeaderPanel/seventhSectionHeading'

// **************** Libraries ****************
// import { Collapse } from 'antd'
// import Swal from 'sweetalert2'

// **************** CSS ****************
import './index.less'
import Ql from '../../../../api/ql'
import { Collapse } from 'antd'
import { useSelector } from 'react-redux'
import { verifyErrorCode } from '../../../../components/ui/LocalizeFirebaseErrorMessages'
import { Notification } from 'element-react'
import { useParams } from 'react-router'
import { SET_ADVISOR_SURVEY_ID } from '../../../../store/actions'
// import '../../../../common.less'

export default function Index({ onFormStateUpdate, showViewResultButton }) {
  const params = useParams()
  const dispatch = useDispatch()

  const [collapseKey, setCollapseKey] = useState(['1']) //key to open up panels
  const [questionnaireUUID, setQuestionnaireUUID] = useState(null)
  const useremail = useSelector(state => state.user_data?.email)

  // const [isUpdate, setIsUpdate] = useState(null)
  const [formState, setFormState] = useState({})
  const [addUpdateButtonClicked, setAddUpdateButtonClicked] = useState(false)

  const [detailsSavedFormNumber, setDetailsSavedFormNumber] = useState(null)
  const [showSavedDetailsFirstForm, setShowSavedDetailsFirstForm] = useState(false)
  const [showSavedDetailsSecondForm, setShowSavedDetailsSecondForm] = useState(false)
  const [showSavedDetailsThirdForm, setShowSavedDetailsThirdForm] = useState(false)
  const [showSavedDetailsFourthForm, setShowSavedDetailsFourthForm] = useState(false)
  const [showSavedDetailsFifthForm, setShowSavedDetailsFifthForm] = useState(false)
  const [showSavedDetailsSixthForm, setShowSavedDetailsSixthForm] = useState(false)
  const [showSavedDetailsSeventhForm, setShowSavedDetailsSeventhForm] = useState(false)
  // const [updatedForm, setUpdatedForm] = useState(null)
  const [advisorQuestions, setAdvisorQuestions] = useState(null)
  const { Panel } = Collapse

  const firstPanelRef = useRef(null)
  const secondPanelRef = useRef(null)
  const thirdPanelRef = useRef(null)
  const fourthPanelRef = useRef(null)
  const fifthPanelRef = useRef(null)
  const sixthPanelRef = useRef(null)
  const seventhPanelRef = useRef(null)

  useEffect(() => {
    // setIsUpdate(null)

    if (advisorQuestions === null) {
      new Ql('getAdvisorQuestions')
        .get()
        .then(response => {
          setAdvisorQuestions(response.data.getAdvisorQuestions)

          if (params['surveyID'] && params['surveyID'] != null) {
            setQuestionnaireUUID(params['surveyID'])
            getAdvisoryAnswers()
          } else if (response.data.getAdvisorQuestions !== null) {
            const r = { ...formState }
            for (var section of response.data.getAdvisorQuestions) {
              for (var question of section.questions) {
                r[question.uuid] = null
              }
            }
            setFormState(r)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    if (addUpdateButtonClicked == false) {
      return
    }

    if (questionnaireUUID == null) {
      addAnswersMutation()
    } else {
      updateAnswersMutation()
    }
    // if (detailsSavedFormNumber == 1) {
    //   if (isUpdate == true) {
    //     updateAnswersMutation() // To be fixed, getting error on adding
    //     // setUpdatedForm(1)
    //   } else {

    //   }
    // } else {
    //   updateAnswersMutation()
    //   // setUpdatedForm(detailsSavedFormNumber)
    // }

    onFormStateUpdate(formState)

    switch (detailsSavedFormNumber) {
      case 1:
        setShowFormTwo()
        const errorElement1 = document.querySelector('.panel1')
        if (errorElement1) {
          errorElement1.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break
      case 2:
        setShowFormThree()
        const errorElement2 = document.querySelector('.panel2')
        if (errorElement2) {
          errorElement2.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break
      case 3:
        const errorElement3 = document.querySelector('.panel3')
        if (errorElement3) {
          errorElement3.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setShowFormFour()
        break
      case 4:
        const errorElement4 = document.querySelector('.panel4')
        if (errorElement4) {
          errorElement4.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setShowFormFive()
        break
      case 5:
        const errorElement5 = document.querySelector('.panel5')
        if (errorElement5) {
          errorElement5.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setShowFormSix()
        break
      case 6:
        const errorElement6 = document.querySelector('.panel6')
        if (errorElement6) {
          errorElement6.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setShowFormSeven()
        break
      case 7:
        const errorElement7 = document.querySelector('.panel7')
        if (errorElement7) {
          errorElement7.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        setShowFormAll()
        showViewResultButton(true)
        break
    }
  }, [formState, detailsSavedFormNumber, addUpdateButtonClicked])

  // Get formatted request data for add and update answers
  const getFormattedRequestData = () => {
    const formattedAnswers = []
    for (var key in formState) {
      if (formState[key] !== null) {
        formattedAnswers.push({
          questionId: key,
          answer: formState[key]
        })
      }
    }

    return formattedAnswers
  }

  const addAnswersMutation = () => {
    const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
    const companyId = Cookies.get('MUDEY_company', { domain: domain }) || 'Mudey'
    new Ql('advisorAnswers')
      .set({ email: useremail, responses: getFormattedRequestData(), companyId })
      .then(data => {
        setQuestionnaireUUID(data.data.addAdvisorAnswers.uuid)
        dispatch({
          type: SET_ADVISOR_SURVEY_ID,
          payload: data.data.addAdvisorAnswers.uuid
        })
        // Notification.success({
        //   title: 'Sucesso!',
        //   message: 'Guardado'
        // })
        // setTimeout(() => {
        //   window.location.assign('/dados/perfil')
        // }, 1700)
      })
      .catch(e => {
        const errorMessage = verifyErrorCode(e.message)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
      })
      .finally(() => {})
  }

  const updateAnswersMutation = () => {
    const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'
    const companyId = Cookies.get('MUDEY_company', { domain: domain }) || 'Mudey'
    new Ql('updateAdvisorAnswers')
      .set({
        uuid: questionnaireUUID,
        email: useremail,
        responses: getFormattedRequestData(),
        companyId
      })
      .then(() => {
        // Notification.success({
        //   title: 'Sucesso!',
        //   message: 'Guardado'
        // })
        // setTimeout(() => {
        //   window.location.assign('/dados/perfil')
        // }, 1700)
      })
      .catch(e => {
        console.log(e)
        const errorMessage = verifyErrorCode(e.message)
        Notification.error({
          title: 'Whoops... deu erro!',
          message: errorMessage
        })
      })
      .finally(() => {})
  }

  const getAdvisoryAnswers = () => {
    new Ql('getAdvisorAnswers')
      .get(null, params['surveyID'])
      .then(data => {
        const answerSectionList = data.data.getAdvisorAnswers

        const fv = { ...formState }
        for (var section of answerSectionList) {
          for (var q of section.questions) {
            // console.log(q.uuid, q.answer[0])
            fv[q.uuid] = q.answer
          }
        }
        setFormState(fv)

        onFormStateUpdate(fv)
        setShowSavedDetailsFirstForm(true)
        setShowSavedDetailsSecondForm(true)
        setShowSavedDetailsThirdForm(true)
        setShowSavedDetailsFourthForm(true)
        setShowSavedDetailsFifthForm(true)
        setShowSavedDetailsSixthForm(true)
        setShowSavedDetailsSeventhForm(true)
        setCollapseKey(['8'])
        showViewResultButton(true)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {})
  }

  function setShowFormOne() {
    setShowSavedDetailsFirstForm(false)
    setCollapseKey(['1'])
  }

  function setShowFormTwo() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(false)
    setCollapseKey(['2'])
  }

  function setShowFormThree() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(false)
    setCollapseKey(['3'])
  }

  function setShowFormFour() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(true)
    setShowSavedDetailsFourthForm(false)
    setCollapseKey(['4'])
  }

  function setShowFormFive() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(true)
    setShowSavedDetailsFourthForm(true)
    setShowSavedDetailsFifthForm(false)
    setCollapseKey(['5'])
  }

  function setShowFormSix() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(true)
    setShowSavedDetailsFourthForm(true)
    setShowSavedDetailsFifthForm(true)
    setShowSavedDetailsSixthForm(false)
    setCollapseKey(['6'])
  }

  function setShowFormSeven() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(true)
    setShowSavedDetailsFourthForm(true)
    setShowSavedDetailsFifthForm(true)
    setShowSavedDetailsSixthForm(true)
    setShowSavedDetailsSeventhForm(false)
    setCollapseKey(['7'])
  }

  function setShowFormAll() {
    setShowSavedDetailsFirstForm(true)
    setShowSavedDetailsSecondForm(true)
    setShowSavedDetailsThirdForm(true)
    setShowSavedDetailsFourthForm(true)
    setShowSavedDetailsFifthForm(true)
    setShowSavedDetailsSixthForm(true)
    setShowSavedDetailsSeventhForm(true)
    setCollapseKey(['8'])
  }

  // Panels Heading to show on all panel headers after saving data
  const firstSectionHeading = (
    <FirstSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsFirstForm}
      heading={advisorQuestions && advisorQuestions[0].title}
      questions={advisorQuestions && advisorQuestions[0].questions}
      showForm={setShowFormOne}
      badge="1"
    />
  )

  const secondSectionHeading = (
    <SecondSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsSecondForm}
      badge="2"
      heading={advisorQuestions && advisorQuestions[1].title}
      questions={advisorQuestions && advisorQuestions[1].questions}
      showForm={setShowFormTwo}
    />
  )

  const thirdSectionHeading = (
    <ThirdSectionHeading
      formValues={formState}
      heading={advisorQuestions && advisorQuestions[2].title}
      questions={advisorQuestions && advisorQuestions[2].questions}
      showSavedDetails={showSavedDetailsThirdForm}
      badge="3"
      showForm={setShowFormThree}
    />
  )

  const fourthSectionHeading = (
    <FourthSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsFourthForm}
      heading={advisorQuestions && advisorQuestions[3].title}
      questions={advisorQuestions && advisorQuestions[3].questions}
      showForm={setShowFormFour}
      badge="4"
    />
  )

  const fifthSectionHeading = (
    <FifthSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsFifthForm}
      heading={advisorQuestions && advisorQuestions[4].title}
      questions={advisorQuestions && advisorQuestions[4].questions}
      showForm={setShowFormFive}
      badge="5"
    />
  )

  const sixthSectionHeading = (
    <SixthSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsSixthForm}
      heading={advisorQuestions && advisorQuestions[5].title}
      questions={advisorQuestions && advisorQuestions[5].questions}
      showForm={setShowFormSix}
      badge="6"
    />
  )

  const seventhSectionHeading = (
    <SeventhSectionHeading
      formValues={formState}
      showSavedDetails={showSavedDetailsSeventhForm}
      heading={advisorQuestions && advisorQuestions[6].title}
      questions={advisorQuestions && advisorQuestions[6].questions}
      showForm={setShowFormSeven}
      badge="7"
    />
  )

  const setFormFunc = (values, formNumber) => {
    if (values && Object.keys(values).length > 0) {
      const currentFormState = { ...formState }
      for (var key in values) {
        currentFormState[key] = values[key]
      }

      setFormState(currentFormState)
    }

    setDetailsSavedFormNumber(formNumber)
    setAddUpdateButtonClicked(true)
  }

  return (
    advisorQuestions !== null && (
      <section className="mortage-journey">
        <Collapse activeKey={collapseKey} style={{ border: 'none' }}>
          {/* First Questionnaire */}
          <Panel
            ref={firstPanelRef}
            header={firstSectionHeading}
            className="panel-class panel1"
            key="1"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <FirstSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 1)}
              advisorQuestions={advisorQuestions && advisorQuestions[0]}
            />
          </Panel>

          {/* Second Questionnaire */}
          <Panel
            ref={secondPanelRef}
            header={secondSectionHeading}
            className="panel-class panel2"
            key="2"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <SecondSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 2)}
              advisorQuestions={advisorQuestions && advisorQuestions[1]}
            />
          </Panel>

          {/* Third Questionnaire */}
          <Panel
            ref={thirdPanelRef}
            header={thirdSectionHeading}
            className="panel-class panel3"
            key="3"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <ThirdSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 3)}
              advisorQuestions={advisorQuestions && advisorQuestions[2]}
            />
          </Panel>

          {/* Fourth Questionnaire */}
          <Panel
            ref={fourthPanelRef}
            header={fourthSectionHeading}
            className="panel-class panel4"
            key="4"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <FourthSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 4)}
              advisorQuestions={advisorQuestions && advisorQuestions[3]}
            />
          </Panel>

          {/* Fifth Questionnaire */}
          <Panel
            ref={fifthPanelRef}
            header={fifthSectionHeading}
            className="panel-class panel5"
            key="5"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <FifthSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 5)}
              advisorQuestions={advisorQuestions && advisorQuestions[4]}
            />
          </Panel>

          {/* Sixth Questionnaire */}
          <Panel
            ref={sixthPanelRef}
            header={sixthSectionHeading}
            className="panel-class panel6"
            key="6"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <SixthSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 6)}
              advisorQuestions={advisorQuestions && advisorQuestions[5]}
            />
          </Panel>

          {/* Seventh Questionnaire */}
          <Panel
            ref={seventhPanelRef}
            header={seventhSectionHeading}
            className="panel-class panel7"
            key="7"
            showArrow={false}
            style={{ backgroundColor: 'white' }}>
            <SeventhSection
              defaultValues={formState}
              setForm={values => setFormFunc(values, 7)}
              advisorQuestions={advisorQuestions && advisorQuestions[6]}
            />
          </Panel>
        </Collapse>
      </section>
    )
  )
}

Index.propTypes = {
  onFormStateUpdate: PropTypes.func.isRequired,
  showViewResultButton: PropTypes.func.isRequired
}
