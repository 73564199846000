// @flow
import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost-upload'
import { store } from 'react-notifications-component'
import { useSelector } from 'react-redux'

import { Spin } from 'antd'

import Ql from '../../api/ql'
import apollo from '../../api/client'
import { Card } from './components/cards'
import { Header } from './components/header'
import { CustomToast } from './components/customToast'

import email from '../../images/icons/email.svg'
import thumbsUp from '../../images/icons/thumbs-up.svg'

import './styles.less'

const Referrals = ({ isMobile }) => {
  const userEmail = useSelector((state: any) => (state.user_data?.email))
  const isPhoneValidAndVerified = useSelector(state => state.isPhoneValidAndVerified)
  const [state, setState] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [declaration, setDeclaration] = useState(false)
  const [loading, setLoading] = useState(false)

  const showNotification = (message: string, type: string) => {
    return store.addNotification({
      content: <CustomToast message={message} type={type} />,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000
      }
    })
  }
  useEffect(() => {
    if (!isPhoneValidAndVerified) return history.push('/verify-number')
  }, [isPhoneValidAndVerified])

  const getProfileData = async () => {
    const result = await new Ql('userData').get()
    if (result) {
      const {
        data: { userGet }
      } = result
      if (userGet) {
        setState({
          ...state,
          referralsSent: userGet.referralsSent,
          referralsCompleted: userGet.referralsCompleted
        })
      }
    }
  }

  const onChangeValue = ({ value, name }: any) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSubmit = async () => {
    if (!state.referredEmail) {
      showNotification('Preencha o e-mail do convidado', 'danger')
    }
    if (!state.referredName) {
      showNotification('Preencha o nome do convidado', 'danger')
    }
    if (declaration === false) {
      showNotification('Falta a autorização', 'danger')
    }

    const { referredEmail, referredName } = state

    if (userEmail && referredEmail && referredName && declaration) {
      setLoading(true)
      try {
        setState({
          ...state,
          referralsSent: state.referralsSent + 1
        })
        await apollo.mutate({
          variables: { referredEmail, referredName },
          mutation: gql`
            mutation createReferral($referredEmail: String, $referredName: String) {
              createReferral(
                input: { referredName: $referredName, referredEmail: $referredEmail }
              ) {
                id
                referredBy
                referredEmail
                referralRegistered
                referredName
              }
            }
          `
        })
        setLoading(false)
        getProfileData()
        setState({})
        showNotification('O teu convite foi enviado', 'success')
      } catch (err) {
        setLoading(false)
        if (err) {
          const message = err.message
            ? err.message.replace('GraphQL error:', '')
            : 'Something goes wrong, try again please'

          setErrorMessage(message)
          showNotification(message, 'danger')
        }
      }
    }
  }

  useEffect(() => {
    getProfileData()
  }, [])

  return (
    <div>
      <Header title="Os Meus Convites" />
      <div className="referral_grid">
        <div className="form_container">
          <div className="form_header">
            <span className="form_header-title">
              Diz-nos quem queres convidar para se juntar à MUDEY!
            </span>
            <span className="form_header-subtitle">
              Irá receber um email nosso com o teu convite…
            </span>
          </div>
          <div className="form_wrapper">
            <div className="form_column">
              <span>Nome do convidado</span>
              <input
                style={{ border: errorMessage ? '2px solid #881414' : '1px solid #aacecd' }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onSubmit()
                  }
                }}
                value={state.referredName}
                onChange={({ target }) => {
                  let value = target.value.replace(/[^a-zA-Z]/gi, '')
                  onChangeValue({
                    name: 'referredName',
                    value: value
                  })
                  errorMessage !== null && setErrorMessage(null)
                }}
                name="referredName"
              />
              {errorMessage && <span style={{ color: '#881414' }}>{errorMessage}</span>}
            </div>

            <div className="form_column">
              <span>Email do convidado</span>
              <input
                style={{ border: errorMessage ? '2px solid #881414' : '1px solid #aacecd' }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onSubmit()
                  }
                }}
                onChange={({ target }) => {
                  onChangeValue({
                    name: 'referredEmail',
                    value: target.value
                  })
                  errorMessage !== null && setErrorMessage(null)
                }}
                name="referredEmail"
              />
              {errorMessage && <span style={{ color: '#881414' }}>{errorMessage}</span>}
            </div>
          </div>

          <div className="form_actions">
            {loading && (
              <div className="form_actions-loading">
                {' '}
                <Spin />{' '}
              </div>
            )}

            {/* Invite declaration */}

            <div className="declaration">
              <label>
                <input
                  value={declaration}
                  type="checkbox"
                  name="declaration"
                  onClick={() => setDeclaration(!declaration)}
                  // onChange={({ target }) => {
                  //   onChangeValue({
                  //     name: 'declaration',
                  //     value: target.value
                  //   })
                  //   errorMessage !== null && setErrorMessage(null)
                  // }}
                />
                <span />
                <span> Autorizo que a MUDEY inclua o meu nome no convite para o meu amigo</span>
              </label>
            </div>
            <div onClick={onSubmit} className="form_button">
              <span>Enviar convite</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="form_cards_container"
        style={{ gridTemplateColumns: !isMobile ? '200px 200px' : '1fr 1fr' }}>
        <Card
          text="Convites enviados"
          backgroundImage={`url(${email})`}
          count={state.referralsSent}
        />
        <Card
          text="Convites aceites"
          backgroundImage={`url(${thumbsUp})`}
          count={state.referralsCompleted}
        />
      </div>
    </div>
  )
}

export default Referrals
