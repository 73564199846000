import 'element-theme-default'

import React from 'react'
import Ql from '../../api/ql'
import PriceAdvisor from '../ui/PriceAdvisor'
import PartnersHeader from '../../../src/components/utils/partners-header'

import './selectProduct.less'

class selectProduct extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showTypeform: '',
      userid: null,
      firstname: null,
      email: null
    }
  }

  componentDidMount() {
    this.getAdvisorResults()
  }
  getAdvisorResults() {
    new Ql('userData').get().then(response => {
      this.setState({ userid: response.data.userGet.uuid })
      this.setState({ firstname: response.data.userGet.firstName })
      this.setState({ email: response.data.userGet.email })
    })
  }

  render() {
    const { showTypeform, userid, firstname, email } = this.state

    const initialUrl = process.env.REACT_APP_MD_URL

    let readyInsurances = []
    if (userid) {
      readyInsurances = [
        {
          name: 'Car',
          title: 'Carro',
          link: `${initialUrl}seguro-carro/simulacao/?overview=${userid}`
        },
        {
          name: 'Life',
          title: 'Vida Proteção',
          link: `${initialUrl}seguro-vida/simulacao/?overview=${userid}`
        },
        {
          name: 'Mortgage',
          title: 'Vida Crédito',
          link: `${initialUrl}seguro-vida-credito/simulacao?overview=${userid}`
        },
        {
          name: 'Health',
          title: 'Saúde',
          link: `${initialUrl}seguro-saude/simulacao`
        },
        {
          name: 'Buildings',
          title: 'Casa (Multirriscos)',
          link: `${initialUrl}seguro-casa/simulacao`
        },
        {
          name: 'Housekeeper',
          title: 'Empregada Doméstica',
          link: `${initialUrl}seguro-empregada/simulacao?overview=${userid}`
        },
        {
          name: 'Dental',
          title: 'Dentário',
          link: `${initialUrl}seguro-dental/simulacao`
        },
        {
          name: 'Travel',
          title: 'Viagem',
          link: `${initialUrl}seguro-viagem/simulacao?overview=${userid}`
        },
        {
          name: 'Accident',
          title: 'Acidentes Pessoais',
          link: `${initialUrl}seguro-acidentes-pessoais/simulacao`
        },
        {
          name: 'Bicycle',
          title: 'Bicicleta',
          link: `https://mudey.pt/produtos/seguro-bicicleta/seguro-bicicleta-simulacao?email=${email}`
        },
        {
          name: 'Drone',
          title: 'Drone',
          link: `https://mudey.pt/produtos/seguro-drones/seguro-drone-simulacao?email=${email}`
        },
        {
          name: 'Child',
          title: 'Criança',
          link: `https://mudey.pt/produtos/seguro-crianca/simulador?email=${email}`
        },
        {
          name: 'CriticalIllness',
          title: 'Doenças Graves',
          link: `${initialUrl}doencas-graves/simulacao/?overview=${userid}`
        },
        {
          name: 'Scooter',
          title: 'Trotinete',
          link: `https://mudey.pt/produtos/seguro-trotinete/simulador?email=${email}`
        },
        {
          name: 'Family',
          title: 'Família',
          link: `https://mudey.pt/produtos/seguro-familia/simulador?email=${email}`
        },
        {
          name: 'PPR',
          title: 'PPR',
          link: `${initialUrl}seguro-ppr/simulacao/?overview=${userid}`
        },
        {
          name: 'Hospitalização',
          title: 'Hospitalização',
          link: `${initialUrl}hospitalização/simulacao`
        },
        {
          name: 'WorkAccident',
          title: 'Acidentes de Trabalho Independentes',
          link: `${initialUrl}seguro-acidentes-trabalho-independente/simulacao/?overview=${userid}`
        },
        {
          name: 'Pet',
          title: 'Animais Domésticos',
          link: `${initialUrl}seguro-animais-domesticos/simulacao/?overview=${userid}`
        },
        {
          name: 'LocalLodging',
          title: 'Alojamento Local',
          link: `${initialUrl}seguro-alojamento-local/simulacao/?overview=${userid}`
        }
      ]
    }

    const getPrices = [
      {
        formName: 'Contents',
        name: 'Contents',
        title: 'Casa (Recheio)'
      },
      {
        formName: 'Bike',
        name: 'Motorbike',
        title: 'Mota'
      },
      {
        formName: 'Workaccidentemployee',
        name: 'WorkAccident',
        title: 'Acid. Trabalho Outrém'
      },
      {
        formName: 'thirdparty',
        name: '3rdParty',
        title: 'Responsabilidade Civil'
      },
      {
        formName: 'investment',
        name: 'Investment',
        title: 'Investimento'
      }
    ]

    return (
      <>
        <div className={showTypeform !== '' ? 'shadowBG' : ''} />
        <div className="header-partner-wrapper">
          <h1>Comprar Seguro</h1>
          <PartnersHeader />
        </div>
        <p>Escolhe qual o seguro que queres comprar com a MUDEY</p>
        <p className="buy-subtitle">Comprar Online</p>

        <div className="cards">
          {readyInsurances.map(e => {
            return (
              <a className="card-block" href={e.link} key={e.name}>
                <div className="image">
                  {e.name == 'PPR' || e.name == 'Investment' || e.name == 'LocalLodging' ? (
                    <>
                      <img
                        alt=""
                        className="shield"
                        src={require(`../../images/types/purple/${e.name}.svg`)}
                      />
                      <img
                        alt=""
                        className="shield shield-white"
                        src={require(`../../images/types/white/${e.name}.svg`)}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        className="shield"
                        src={require(`../../images/types/purple/${e.name}.svg`)}
                      />
                      <img
                        alt=""
                        className="shield shield-white"
                        src={require(`../../images/types/white/${e.name}.svg`)}
                      />
                    </>
                  )}

                  <div className="title">{e.title}</div>
                </div>
              </a>
            )
          })}
          <div className="arrow-mb">
            Desliza para a <span>direita</span> &gt;
          </div>
        </div>
        <p className="buy-subtitle">Solicitar Preços</p>

        <div className="cards">
          {getPrices.map(e => {
            return (
              <>
                <div
                  className="card-block"
                  key={e.name}
                  onClick={() => this.setState({ showTypeform: e.formName })}>
                    <div className="image">
                      <img
                        alt=""
                        className="shield"
                        src={require(`../../images/types/purple/${e.name}.svg`)}
                      />
                      <img
                        alt=""
                        className="shield shield-white"
                        src={require(`../../images/types/white/${e.name}.svg`)}
                      />
                      <div className="title">{e.title}</div>
                    </div>
                </div>
                {showTypeform == e.formName ? (
                  <div className="modal-info">
                    <div className="typeform-wrapper">
                      {new PriceAdvisor(`${e.formName}`, userid, firstname, email).getForm()}
                      <a
                        className="button-solid"
                        href="#consider"
                        onClick={() => this.setState({ showTypeform: '' })}>
                        X
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            )
          })}
          <div className="arrow-mb">
            Desliza para a <span>direita</span> &gt;
          </div>
        </div>
      </>
    )
  }
}

export default selectProduct
